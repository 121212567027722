const ptBR = {
  confirmarCadastro: {
    campoObrigatorio: 'Campo obrigatório',
    emailInvalido: 'Email inválido',
    minimoSenha: 'A senha deve ter no mínimo 6 caracteres',
    senha: 'Senha *',
    senhasIncompativeis: 'Senhas incompativeis',
    confirmarSenha: 'Confirmar senha',
    codigo: 'Código',
    sucesso: 'Sucesso',
    codigoInvalido: 'Código inválido',
    falhaConectar: 'Não foi possível trocar a senha, tente novamente',
    confirmarCadastro: 'Confirmar cadastro',
    falhaValidarCadastro: 'Falha ao validar cadastro',
    cadastroJaConfirmado: 'Cadastro já confirmado',
    cadastroConfirmado: 'Cadastro confirmado com sucesso'
  }
};

export default ptBR;
