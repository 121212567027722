import Token from 'root-models/token';
/**
 * Classes responsável por manipular o token no localstorage
 *
 * @author Gabriela Farias
 * @author Bruno Eduardo
 */
class TokenController {
  /**
   * Key to token no localStorage
   */
  private static readonly TOKEN_KEY = 'token';
  /**
   * @returns {(Token | null)} Objeto do token de autenticação do localStorage
   */
  public static getToken(): Token | null {
    const stringObj = localStorage.getItem(TokenController.TOKEN_KEY);
    return stringObj ? JSON.parse(stringObj) : null;
  }
  /**
   * Setta o objeto do token de autenticação no localStorage
   *
   * @param {(Token | null)} token - Objeto do token
   */
  public static setToken(token: Token | null) {
    localStorage.setItem(TokenController.TOKEN_KEY, token ? JSON.stringify(token) : '');
  }
}
export default TokenController;