/**
 * Responsável pela memória das URL's das API's do sistema
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @abstract
 */
abstract class URL {
  /**
   * URL da API de autenticação
   *
   * @static
   */
  public static readonly OAUTH = process.env.REACT_APP_URL_OAUTH;

  /**
   * URL da API principal
   *
   * @static
   */
  public static readonly API = process.env.REACT_APP_PIANI_API_URL_GENERAL;

  /**
   * URL da API DNE
   * 
   * @static
   */
  public static readonly DNEAPI = process.env.REACT_APP_PIANI_API_URL_DNE;

  /**
   * URL da API Logistica
   * 
   * @static
   */
  public static readonly LOGISTICAAPI = process.env.REACT_APP_PIANI_API_URL_LOGISTICA;

  /**
   * URL da API PCP
   * 
   * @static
   */
  public static readonly PCPAPI = process.env.REACT_APP_PIANI_API_URL_PCP;

  /**
   * URL da API FATURAMENTO
   * 
   * @static
   */
  public static readonly FATURAMENTOAPI = process.env.REACT_APP_PIANI_API_URL_FATURAMENTO;

  /**
   * URL da API VENDAS
   * 
   * @static
   */
  public static readonly VENDASAPI = process.env.REACT_APP_PIANI_API_URL_VENDAS;

  /**
   * URL da API FISCAL
   * 
   * @static
   */
  public static readonly FISCALAPI = process.env.REACT_APP_PIANI_API_URL_FISCAL;

  /**
   * URL da API ESTOQUE
   * 
   * @static
   */
  public static readonly ESTOQUEAPI = process.env.REACT_APP_PIANI_API_URL_ESTOQUE;

  /**
   * URL da API ORDEM DE SERVIÇO
   * 
   * @static
   */
  public static readonly OSAPI = process.env.REACT_APP_PIANI_API_URL_OS;

  /**
   * URL da API QA
   * 
   * @static
   */
  public static readonly QAAPI = process.env.REACT_APP_PIANI_API_URL_QA;

  /**
   * URL da API de Reports do PCP
   * 
   * @static
   */
  public static readonly REPORTPCP = process.env.REACT_APP_PIANI_API_URL_REPORT_PCP;

  /**
   * URL da API de Reports do General
   * 
   * @static
   */
  public static readonly REPORTGENERAL = process.env.REACT_APP_PIANI_API_URL_REPORT_GENERAL;

  /**
   * URL da API de RP Flex
   * 
   * @static
   */
  public static readonly RPFLEX = process.env.REACT_APP_PIANI_API_URL_RPFLEX;
  
  /**
   * URL da API de COMPRA
   * 
   * @static
   */
  public static readonly COMPRAAPI = process.env.REACT_APP_PIANI_API_URL_COMPRA;
}

export default URL;