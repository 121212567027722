import { makeStyles } from '@mui/styles';

import Background from 'root-images/background_login.png';

/**
 * Estilos da tela de troca de empresas
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 **/
const useStyles = makeStyles((theme: any) => ({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainContent: {
    width: '70%', 
    display: 'flex', 
    justifyContent: 'center',
    maxHeight: 190
  },
  companyContainer: {
    borderRadius: '15px',
    border: `1px solid rgba(178, 178, 178, 0.2)`,
    padding: '10px 25px',
    maxHeight: '195px',
    color: theme.palette.common.white,
    margin: '0px 7.5px !important',
    maxWidth: '145px !important',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: '38px',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    transition: 'all .2s ease-in-out',
  },
  selectedCompany: {
    border: `2px solid ${theme.palette.common.white} !important`
  },
  logo: {
    '& img': {
      margin: '10px 0',
    },
    height: 95,
    width: 95,
    borderRadius: 50,
    background: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  settingsIcon: {
    left: 50,
    color: '#FFF',
    top: 20,
    height: '10px',
    width: 10,
    zIndex: 3,
    cursor: 'pointer'
  },
  nameCompany: {
    lineHeight: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tornarPadrao: {
    border: `1px solid ${theme.palette.primary.main}`,
    height: '35px',
    width: 105,
    borderRadius: 5,
    zIndex: 5,
    color: '#FFF',
    top: 20,
    background: '#142144',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    fontSize: 12,
    cursor: 'pointer',
    position: 'absolute'
  }
}));

export { useStyles };
