/**
 * Enum de exceções tratadas do sistema
 *
 * @author Bruno Eduardo
 */
enum ExceptionEnum {
  CLIENTE_INATIVO = 'CLIENTE_INATIVO',
  USUARIO_NAO_ENCONTRADO = 'USUARIO_NAO_ENCONTRADO',
  CODIGO_INVALIDO = 'CODIGO_INVALIDO',
  CODIGO_EXPIRADO = 'CODIGO_EXPIRADO',
}

export default ExceptionEnum;
