import GenericApi from 'root-resources/generic-api'
import Filial from 'root-models/cadastros/filial/filial';

/**
 * API da Empresa Filial
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class FilialAPI extends GenericApi<Filial> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super({ baseEndpoint: 'filial' });
  }

}

export default FilialAPI
