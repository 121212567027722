import React, { useEffect, useCallback, lazy, Suspense } from 'react';
import AppMainLayout from 'root-views';
import { Loading } from '@bubotech/sumora-react-components/lib';
import AlterarEmpresa from './alterarempresa/views/alterar-empresa';
import RecuperarSenha from 'root-views/recuperarsenha/recuperar-senha';
import ConfirmarConta from 'root-views/confirmarconta/confirmar-conta';
import { BrowserRouter, Route, useNavigate, Routes } from 'react-router-dom';
import ConfirmarCadastro from 'root-views/confirmarcadastro/confirmar-cadastro';

const Login = lazy(() => import('root-views/login/login'));

/**
 * Cria e declara as rotas principais do App
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 */
function createRoutes(): JSX.Element {
  function Redirect({ to }: any) {
    let navigate = useNavigate();
    const navigateAux = useCallback(navigate, [navigate]);
    useEffect(() => {
      navigateAux(to);
    }, [navigateAux, to]);
    return null;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading show={true} style={{ zIndex: 100, position: 'absolute' }} />}>
        <Routes>
          <Route path='/' element={<Redirect to='/dashboard' />} />
          <Route path='/login' element={<Login />} />
          <Route path='/alterar-empresa' element={<AlterarEmpresa />} />
          <Route path='/resetar-senha' element={<RecuperarSenha />} />
          <Route path='/confirmar-cadastro' element={<ConfirmarCadastro />} />
          <Route path='/confirmar-conta/:id' element={<ConfirmarConta />} />
          <Route path='/dashboard' element={<AppMainLayout />} />
          <Route path='/cadastros/*' element={<AppMainLayout />} />
          <Route path='/modulo-fiscal/*' element={<AppMainLayout />} />
          <Route path='/modulo-documento-fiscal/*' element={<AppMainLayout />} />
          <Route path='/modulo-faturamento/*' element={<AppMainLayout />} />
          <Route path='/modulo-vendas/*' element={<AppMainLayout />} />
          <Route path='/modulo-ordem-servico/*' element={<AppMainLayout />} />
          <Route path='/modulo-pcp/*' element={<AppMainLayout />} />
          <Route path='/configuracoes-acesso/*' element={<AppMainLayout />} />
          <Route path='/rpflex/*' element={<AppMainLayout />} />
          <Route path='/modulo-logistica/*' element={<AppMainLayout />} />
          <Route path='/modulo-estoque/*' element={<AppMainLayout />} />
          <Route path='/precos/*' element={<AppMainLayout />} />
          <Route path='/questionarios/*' element={<AppMainLayout />} />
          <Route path='/modulo-financeiro/*' element={<AppMainLayout />} />
          <Route path='/modulo-compra/*' element={<AppMainLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default createRoutes;
