import React, { useState, useEffect } from 'react';
import { Dispatch } from 'redux';
import List from '@mui/material/List';
import { useStyles } from './menu.styles';
import { MainStateType } from 'root-states';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import { MenuLateralItemModel } from './menu-itens-definitions';
import ContextActions from 'root-states/actions/context-actions';
import AppLayoutActions from 'root-states/actions/app-layout-actions';

export type MenuLateralItemPropType = {
  /**
   * Definições do item que vai ser renderizado nesse componente
   */
  item: MenuLateralItemModel;
  /**
   * Nível do item no menu lateral
   * valor inicial=0
   */
  level: number;
  selected?: string;
  currentModule?: string;
  setSelected: (value: string) => void;
};

/**
 * Representa um item do menu lateral, em qualquer nível
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {MenuLateralItemPropType} props
 */
function MenuLateralItem(props: MenuLateralItemPropType): JSX.Element {
  const classes = useStyles();
  const { item, level, selected, currentModule, setSelected } = props;
  const [collapseIsOpen, setCollapseIsOpen] = useState<boolean | undefined>(undefined);
  const [currentOption, setCurrentOption] = useState<string>('');
  const history = useNavigate();
  const menuIsOpen = useSelector<MainStateType, boolean>(state => state.appLayoutReducer.menuOpen);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());

  const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  const allowRouteChange = useSelector<MainStateType, boolean>(state => state.contextReducer.allowRouteChange);

  useEffect(() => {
    !menuIsOpen && setCollapseIsOpen(false);
  }, [menuIsOpen]);

  function handleClickItem() {
    appLayoutActions.setMenuIsOpen(true);
          
    if (item.childrens && item.childrens.length > 0) {
      setCollapseIsOpen(!collapseIsOpen);
      setCurrentOption(item.label as string);
      return;
    } else if (typeof item.label === 'string' && item.Icon) {
      setSelected(item.label)
    }
    
    if (item.route) {
      menuIsOpen && appLayoutActions.setMenuIsOpen(false);
      contextActions.tryChangeRoute(item.route);
      if (allowRouteChange) {
        contextActions.changeRoute(item.route);
        if (currentModule)
          setSelected(currentModule);
        history(item.route);
      }
    }
  }

  function getItemStyle(): React.CSSProperties | undefined {
    if (level) 
      return { boxSizing: 'border-box', paddingLeft: 50 + 20 * level, color: '#FFF' };
    
    if (selected === item.label)
      return { borderLeft: '2px white solid' };

    return undefined;
  }

  return (
    <>
      <ListItem
        button
        className={!menuIsOpen ? classes.containerItem : undefined}
        onClick={handleClickItem}
        style={getItemStyle()}
      >
        {item.Icon && (
          <ListItemIcon className={classes.itemIcon}>
            {item.Icon({ style: { height: 20, width: 20, marginLeft: selected === item.label ? '-2px' : 0 } })}
          </ListItemIcon>
        )}

        <ListItemText primary={item.label} className={`${level ? 'submenu' : ''}`} />

        {item.childrens && item.childrens.length > 0 && (
          <IconButton edge='end' color='inherit'>
            <ArrowIcon
              color='inherit'
              style={
                collapseIsOpen !== undefined
                  ? {
                    transform: collapseIsOpen ? 'rotate(90deg)' : undefined,
                    transition: 'transform 0.3s linear'
                  }
                  : undefined
              }
            />
          </IconButton>
        )}
      </ListItem>

      {item.childrens && item.childrens.length > 0 && (
        <Collapse
          in={collapseIsOpen && menuIsOpen}
          timeout='auto'
          unmountOnExit
          style={
            item.childrensBackgroundColor
              ? { backgroundColor: item.childrensBackgroundColor }
              : undefined
          }
        >
          <List component='div' className={classes.containerItens}>
            {item.childrens.map((subItem, i) => <MenuLateralItem setSelected={setSelected} currentModule={currentModule || currentOption} item={subItem} level={level + 1} key={i} />)}
          </List>
        </Collapse>
      )}
    </>
  );
}

export default MenuLateralItem;
