import Swal from '../swal/swal';
import Busca from 'root-models/parametrosbusca';
import { useStylesFilial } from './filial.style';
import { useState, useRef, useEffect, useCallback } from 'react';
import Filial from 'root-models/cadastros/filial/filial';
import { IconButton, Tooltip } from "@mui/material";
import FilialAPI from 'root-resources/api/cadastros/filial/filial';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import { Dispatch } from 'redux';

function AutoCompleteFilial() {
    const api = new FilialAPI();
    const classe = useStylesFilial();
    const params = useRef<Busca>({ page: 1, orderField: 'nmFantasia', orderType: 'ASC', staticParams: ',stEmpresa:1' });
    const updateFilial = useSelector<MainStateType, boolean | undefined>((state) => state.appLayoutReducer.filiais);
    const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
    const containerRef = useRef<HTMLButtonElement | null>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedSigla, setSelectedSigla] = useState<string>('');
    const [dataFiltered, setDataFiltered] = useState<Filial[]>([]);

    const handleItemClick = (filial: Filial) => {
        setSelectedSigla(filial.sgEmpresa);
        appLayoutActions.changeFilial(filial);
        setIsOpen(false);
    };

    //logica para fechar a lista quando usuario clicar fora do componente
    const handleOutsideClick = useCallback(
        (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        },
        []
    );

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    useEffect(() => {
        if (updateFilial !== undefined) {
            api.findByPage(params.current)
                .then(res => {
                    setDataFiltered(res.data.data as Filial[]);
                    setSelectedSigla((res.data.data[0] as Filial).sgEmpresa ?? '');
                    appLayoutActions.changeFilial(res.data.data[0]);
                })
                .catch((err) => {
                    setDataFiltered([]);
                    Swal({
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Ok',
                        title: 'Ocorreu um erro',
                        text: 'Falha ao carregar Filiais',
                        icon: 'error',
                    });
                }).finally(() => appLayoutActions.updateFiliais(undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFilial]);
    return (
        <>
            <Tooltip title={'Alterar Filial'}>
                <span>
                    <IconButton
                        className={classe.filialContainer}
                        ref={containerRef}
                        onClick={() => setIsOpen(true)}
                        disabled={dataFiltered.length <= 1}
                    >
                        <KeyboardArrowUpIcon
                            color='inherit'
                            style={
                                isOpen ?
                                    {
                                        transform: isOpen ? 'rotate(180deg)' : undefined,
                                        transition: 'transform 0.3s linear',
                                        marginRight: '6px',
                                    }
                                    : {
                                        marginRight: '6px'
                                    }
                            }
                        />
                        <span className={classe.label}>{selectedSigla}</span>
                    </IconButton>
                </span>
            </Tooltip>
            {isOpen && (
                <div className={classe.containerList} >
                    <ul>
                        {dataFiltered.map((filial, index) => (
                            <li
                                key={index}
                                className={classe.linhaList}
                                onClick={() => handleItemClick(filial)}
                            >
                                {`${filial.nmFantasia} - ${filial.sgEmpresa}`}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default AutoCompleteFilial;