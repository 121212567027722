import { Dispatch } from 'redux';
import RootDispatcher, { DispatchAction } from '../root-dispatcher';
import ContextActionTypes from '../actiontypes/context-action-types';

/**
 * Actions para o Reducer de contexto do APP
 *
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 * @class ContextActions
 * @extends {RootDispatcher}
 */
class ContextActions extends RootDispatcher {
    /**
    * Construtor padrão que injeta o dispatch
    *
    * @param {Dispatch<DispatchAction>} dispatch
    */
    public constructor(dispatch: Dispatch<DispatchAction>) {
        super(dispatch);
    }

    /**
     * Seta se é permitido mudar de aba
     * @param {boolean} allow
     */
    public allowTabChange = (allow?: boolean) => {
        this.dispatch({
            type: ContextActionTypes.ALLOW_TAB_CHANGE,
            payload: allow
        });
    };

    /**
     * Seta se é permitido mudar de rota
     * @param {boolean} allow
     */
    public allowRouteChange = (allow?: boolean) => {
        this.dispatch({
            type: ContextActionTypes.ALLOW_ROUTE_CHANGE,
            payload: allow
        });
    };

    /**
     * Seta a aba que está sendo selecionada
     * @param {number} tab
     */
    public tryChangeTab = (tab: number) => {
        this.dispatch({
            type: ContextActionTypes.TRY_CHANGE_TAB,
            payload: tab
        });
    };

    /**
     * Seta a rota que está sendo selecionada
     * @param {string} route
     */
    public tryChangeRoute = (route: string) => {
        this.dispatch({
            type: ContextActionTypes.TRY_CHANGE_ROUTE,
            payload: route
        });
    };

    /**
     * Seta a aba atual independente de permissão
     * @param {number} tab
     */
    public changeTab = (tab: number) => {
        this.dispatch({
            type: ContextActionTypes.SET_CURRENT_TAB,
            payload: tab
        });
    };

    /**
     * Seta a rota atual independente de permissão
     * @param {string} route
     */
    public changeRoute = (route: string) => {
        this.dispatch({
            type: ContextActionTypes.SET_CURRENT_ROUTE,
            payload: route
        });
    };

    /**
     * Seta se a aba principal tem dados não salvos
     */
    public principalTabHasUnsavedData = (hasUnsavedData: boolean) => {
        this.dispatch({
            type: ContextActionTypes.PRINCIPAL_TAB_HAS_UNSAVED_DATA,
            payload: hasUnsavedData
        });
    };

    /**
     * Seta o handleSubmit do formulário principal
     */
    public setPrincipalFormSubmit = (handleSubmit: () => void) => {
        this.dispatch({
            type: ContextActionTypes.SET_PRINCIPAL_FORM_SUBMIT,
            payload: handleSubmit
        });
    };
}

export default ContextActions;