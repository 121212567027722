import { makeStyles } from '@mui/styles';

/**
 * Estilos da view de confirmar conta
 **/
const useStyles = makeStyles({
  card: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100vh',
    position: 'absolute',
    width: '100%',
    flexDirection: 'column'
  },
  text: {
    fontSize: 18,
    marginTop: 30,
    fontWeight: 400,
    color: 'rgb(0, 0, 0, 0.6)'
  },
  logo: {
    position: 'absolute',
    marginTop: -27
  },
  confirmacao: {
    position: 'absolute',
    marginTop: 64
  }
});

export { useStyles };
