import React from 'react';
import { Dispatch } from 'redux';
import { MainStateType } from 'root-states';
import Tooltip from '@mui/material/Tooltip';
import { useStyles } from './toolbar.styles';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { DispatchAction } from 'root-states/root-dispatcher';
import Button from '@bubotech/sumora-react-components/lib/button';
import Authentication from 'root-resources/oauth/authentication';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import AutoCompleteFilial from '../autocompletefilial/autocompletefilial';

// css
import './loader.css';

// Ícones
import AlterarEmpresa from '../../assets/icons/building-solid.svg';
import { Notifications, Person, Menu, Logout } from '@mui/icons-material';
import Empresa from 'root-models/empresa';

export type MainToolbarPropType = {}

/**
 * Representa o header/toolbar do layout principal
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {MainToolbarPropType} props
 */
function MainToolbar(props: MainToolbarPropType): JSX.Element {
  const history = useNavigate();
  const classes = useStyles(props);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const company = useSelector<MainStateType, Empresa | undefined>((state) => state.appLayoutReducer?.company);
  const titleToolbar = useSelector<MainStateType, string>((state) => state.appLayoutReducer.titleToolbar);
  const isCalculating = useSelector<MainStateType, boolean>((state) => state.appLayoutReducer.calculating);

  const handleLogout = () => {
    Authentication.logout();
    history('/login');
  }

  return (
    <div className={classes.container}>
      {isCalculating &&
        <div className={classes.loading}>
          <div className="loader"><div></div><div></div><div></div></div>
          Calculando...
        </div>
      }
      <div className={classes.containerLeftItem}>
        <Tooltip title={'Abrir/Fechar menu lateral'}>
          <div>
            <Button className={classes.buttonMenu} onClick={() => appLayoutActions.setMenuIsOpen()} disableElevation>
              <Menu color='inherit' />
            </Button>
          </div>
        </Tooltip>

        <span className={classes.titleToolbar}>{titleToolbar}</span>
      </div>

      <div className={classes.containerRightItem}>
        <Tooltip title={'Alterar Empresa'}>
          <IconButton className={classes.empresaContainer} onClick={() => history('/alterar-empresa')}>
            {company?.nmEmpresa}
            <img src={AlterarEmpresa} alt='alterar-empresa' className={classes.iconEmpresa} />
          </IconButton>
        </Tooltip>
        <AutoCompleteFilial />
        <Tooltip title={'Notificações'}>
          <IconButton className={classes.icone}>
            <Notifications fontSize='inherit' />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Perfil'}>
          <IconButton onClick={() => history('/app/perfil')} className={classes.icone}>
            <Person fontSize='inherit' />
          </IconButton>
        </Tooltip>

        <Tooltip title={'Sair'}>
          <IconButton onClick={handleLogout} className={classes.icone}>
            <Logout fontSize='inherit' />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default MainToolbar;
