import GenericApi from 'root-resources/generic-api'
import Empresa from 'root-models/empresa'
import { AxiosResponse } from 'axios'
import URL from 'root-resources/URL'

/**
 * API da Usuario
 *
 * @author Matheus Rodrigues <matheus.rodrigues@kepha.com.br>
 * @extends {RootApi}
 */
class GeneralAPI<E> extends GenericApi<E> {
  public constructor(value?: string) {
    super({ baseURL: `${URL.OAUTH + (value ?? '')}` })
  }
}

/**
 * API do Usuário
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 * @extends {RootApi}
 */
class EmpresaAPI extends GeneralAPI<Empresa> {
  /**
   * Injeta a instância do axios com o endpoint base referente ao Usuário
   */
  public constructor() {
    super('usuario')
  }

  public async getEmpresaByUsuario(): Promise<AxiosResponse<Empresa[]>> {
    return await this.api.get<Empresa[]>(`/empresa`)
  }
}

export default EmpresaAPI
