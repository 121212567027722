import { makeStyles } from '@mui/styles'


import BackgroundLogin from 'root-images/background_login.png'

/**
 * Estilos da view de recuperar senha
 **/

const useStyles = makeStyles((theme: any) => ({
  background: {
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${BackgroundLogin})`,
    background: '#e5e5e5',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    transition: ' height 220ms',
    width: 360,
    paddingTop: 100,
    marginTop: -100,
  },
  grid: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
  },
  retornarLogin: {
    paddingRight: 12,
    display: 'flex',
    width: '100%',
    height: 60,
    marginBottom: 10,
    fontSize: 14,
    color: `${theme.palette.primary.main}`,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 300,
  },
  label: {
    textDecoration: 'none',
    color: `${theme.palette.primary.main}`,
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'row-reverse',
  },
  button: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
  },
  labelButton: {
    width: 126,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 300,
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  textField: {
    height: 75,
    padding: 12,
  },
}))

export { useStyles }
