import { makeStyles } from '@mui/styles'


const useStylesFilial = makeStyles((theme: any) => ({
  containerList: {
    position: 'absolute',
    width: '22rem',
    backgroundColor: '#fff',
    maxHeight: '300px',
    overflowY: 'auto', 
    zIndex: 9999,
    right: '9.2rem',
    top: '39px',
    border: `1px solid ${theme.palette.grey['100']} !important`,
    borderRadius: '8px',

    //estilo do scroll
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.grey['100']}`,
    '&::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.grey['100']}`,
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: `${theme.palette.grey['300']}`,
    }
  },
  linhaList: {
    padding: '16px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: `${theme.palette.grey['500']}`,
    borderBottom: `1px solid ${theme.palette.grey['100']} !important`,
    fontSize: '16px !important',
    listStyle: 'none',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      cursor: 'pointer',
    }
  },
  filialContainer: {
    color: `${theme.palette.primary.main} !important`,
    border: `1px solid ${theme.palette.grey['100']} !important`,
    borderRadius: '6px !important',
    margin: '0 8px 0 16px !important',
    height: '32px !important',
    padding: '6px 8px !important'
  },
  label: {
    fontSize: 12,
    fontFamily: 'Roboto'
  }
}))

export { useStylesFilial }
