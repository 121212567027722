import { Dispatch, Action } from 'redux';

/**
 * Modelo de uma Action do Redux
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @interface DispatchAction
 * @extends {Action<string>}
 */
export interface DispatchAction extends Action<string> {
  payload?: any;
}

/**
 * Classe para ser extendida pelas Actions do Redux
 *
 * @author Bruno Eduardo <bruno.soares@kepha.com.br>
 * @abstract - Não pode ser instânciada, apenas extendida
 */
abstract class RootDispatcher {
  /**
   * Memória do dispatch que vai ser injetado
   *
   * @protected - Apenas esta classe e classes que a extendem podem acessá-la
   * @type {Dispatch<DispatchAction>}
   */
  protected readonly dispatch: Dispatch<DispatchAction>;

  /**
   * Construtor protegido que injeta o Dispatch
   *
   * @param {Dispatch<DispatchAction>} dispatch
   */
  protected constructor(dispatch: Dispatch<DispatchAction>) {
    this.dispatch = dispatch;
  }
}

export default RootDispatcher;
