/**
 * Enum de ActionTypes para o Reducer de Context
 *
 * @enum {number}
 */
enum ContextActionTypes {
    ALLOW_TAB_CHANGE = 'ALLOW_TAB_CHANGE',
    ALLOW_ROUTE_CHANGE = 'ALLOW_ROUTE_CHANGE',
    TRY_CHANGE_TAB = 'TRY_CHANGE_TAB',
    TRY_CHANGE_ROUTE = 'TRY_CHANGE_ROUTE',
    SET_CURRENT_TAB = 'SET_CURRENT_TAB',
    SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE',
    PRINCIPAL_TAB_HAS_UNSAVED_DATA = 'PRINCIPAL_TAB_HAS_UNSAVED_DATA',
    SET_PRINCIPAL_FORM_SUBMIT = 'SET_PRINCIPAL_FORM_SUBMIT'
}

export default ContextActionTypes;