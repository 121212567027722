/**
 * Enum de ActionTypes para o Reducer do CardFilters
 *
 * @enum {number}
 */
enum CardFiltersActionTypes {
  PERSIST_FILTERS_AND_ORDERS = 'PERSIST_FILTERS_AND_ORDERS',
  CHANGE_PAGE = 'CHANGE_PAGE',
}

export default CardFiltersActionTypes;
