import React, { useState } from 'react';
import { useFormik } from 'formik';
import Card from '@bubotech/sumora-react-components/lib/card';
import TextField from '@bubotech/sumora-react-components/lib/textfield';
import Button from '@bubotech/sumora-react-components/lib/button';
import { useStyles } from './confirmar-cadastro.styles';
import Cabecalho from 'root-components/cabecalho/cabecalho';
import { Grid } from '@mui/material';
import { Email, EnhancedEncryption } from '@mui/icons-material';
import * as Yup from 'yup';
import InputAdornment from '@mui/material/InputAdornment';
import useMessages from '@bubotech/sumora-react-components/lib/utils/language';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import Authentication from 'root-resources/oauth/authentication';
import Swal from 'root-components/swal/swal';
import { useNavigate } from 'react-router-dom';
import ExceptionEnum from 'root-resources/exception-enum';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';

export type ConfirmarCadastroPropType = {};

/**
 * View Confirmar cadastro
 *
 * @author Gabriela Farias <gabriela.farias@kepha.com.br>
 */
function ConfirmarCadastro(props: ConfirmarCadastroPropType): JSX.Element {
  const auth = new Authentication();
  const classes = useStyles(props);
  const formatMessage = useMessages({ 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES });
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [dados, setDados] = useState({ email: '', code: '' });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: dados.email,
      codigo: dados.code,
      password: '',
      confirmPassword: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(formatMessage('confirmarCadastro.emailInvalido'))
        .required(formatMessage('confirmarCadastro.campoObrigatorio')),
      codigo: Yup.string().required(formatMessage('confirmarCadastro.campoObrigatorio')),
      password: Yup.string()
        .min(6, formatMessage('confirmarCadastro.minimoSenha'))
        .required(formatMessage('confirmarCadastro.campoObrigatorio')),
      confirmPassword: Yup.string()
        .min(6, formatMessage('confirmarCadastro.minimoSenha'))
        .oneOf([Yup.ref('password')], formatMessage('confirmarCadastro.senhasIncompativeis'))
        .required(formatMessage('confirmarCadastro.campoObrigatorio'))
    }),
    onSubmit: submit
  });

  useComponentDidMount(() => {
    const dadosUsuario: any = {};

    const query = window.location.search.slice(1);
    query.split('&').forEach(parte => {
      const chaveValor = parte.split('=');
      dadosUsuario[chaveValor[0]] = chaveValor[1];
    });

    setDados(dadosUsuario);
  });

  return (
    <div className={classes.background}>
      <Cabecalho />

      <Card className={classes.card}>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={12} className={classes.textField}>
            <TextField
              variant='standard'
              disabled
              type='email'
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email !== undefined}
              helperText={touched.email && errors.email !== '' ? errors.email : null}
              label='E-mail *'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Email className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.textField}>
            <TextField
              variant='standard'
              name='password'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && errors.password !== undefined}
              helperText={touched.password && errors.password !== '' ? errors.password : null}
              value={values.password}
              label={formatMessage('confirmarCadastro.senha')}
              id='adornment-password'
              type={'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <EnhancedEncryption className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.textField}>
            <TextField
              variant='standard'
              name='confirmPassword'
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.confirmPassword && errors.confirmPassword !== undefined}
              helperText={
                touched.confirmPassword && errors.confirmPassword !== '' ? errors.confirmPassword : null
              }
              value={values.confirmPassword}
              label={formatMessage('confirmarCadastro.confirmarSenha')}
              id='adornment-confirmPassword'
              type='password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <EnhancedEncryption className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <div className={classes.button}>
            <Button
              className={classes.labelButton}
              CircularProgressProps={{ color: 'primary' }}
              loading={loading}
              disabled={loading}
              style={{ padding: loading ? 0 : '8px 16px' }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {formatMessage('confirmarCadastro.confirmarCadastro')}
            </Button>
          </div>
        </Grid>
      </Card>
    </div>
  );

  /**
   * Realiza a validação para salvar a senha
   */
  function submit() {
    setLoading(true);

    auth
      .confirmarCadastro(values.email, values.password, values.codigo)
      .then(() => {
        setLoading(false);

        Swal({
          showConfirmButton: true,
          showCancelButton: false,
          title: formatMessage('confirmarCadastro.sucesso'),
          text: formatMessage('confirmarCadastro.cadastroConfirmado'),
          icon: 'success'
        }).then(() => {
          history('/login');
        });
      })
      .catch(err => {
        setLoading(false);

        if (err.response?.data.codigo === ExceptionEnum.CODIGO_INVALIDO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('confirmarCadastro.falhaValidarCadastro'),
            text: formatMessage('confirmarCadastro.codigoInvalido'),
            icon: 'error'
          });
          return;
        }

        if (err.response?.data.codigo === ExceptionEnum.CODIGO_EXPIRADO) {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: formatMessage('confirmarCadastro.falhaValidarCadastro'),
            text: formatMessage('confirmarCadastro.cadastroJaConfirmado'),
            icon: 'error'
          });
          return;
        }

        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: formatMessage('confirmarCadastro.falhaValidarCadastro'),
          text: formatMessage('confirmarCadastro.falhaConectar'),
          icon: 'error'
        });
      });
  }
}

export default ConfirmarCadastro;
