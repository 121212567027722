import { makeStyles } from '@mui/styles';

/**
 * Estilos do cabeçalho
 **/
const useStyles = makeStyles((theme: any) => ({
  card: {
    zIndex: 1000,
    display: 'flex',
    width: 320,
    height: 180,
    backgroundColor: `${theme.palette.primary.main} !important`,
    flexDirection: 'column',
    borderRadius: '10px !important',
    alignItems: 'center',
    padding: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    marginTop: 20,
    width: 250,
    height: 98
  }
}));

export { useStyles };
