/**
 * Enum de ActionTypes para o Reducer do Layout do App
 *
 * @enum {number}
 */
enum AppLayoutActionTypes {
  MENU_IS_OPEN = 'MENU_IS_OPEN',
  CHANGE_TITLE_TOOLBAR = 'CHANGE_TITLE_TOOLBAR',
  IS_LOADING = 'IS_LOADING',
  CHANGE_COMPANY = 'CHANGE_COMPANY',
  CHANGE_STANDARD_COMPANY = 'CHANGE_STANDARD_COMPANY',
  CHANGE_USUARIO = 'CHANGE_USUARIO',
  IS_CALCULATING = 'IS_CALCULATING',
  UPDATE_FILIAIS = 'UPDATE_FILIAIS',
  CHANGE_FILIAL = 'CHANGE_FILIAL'
}

export default AppLayoutActionTypes;
