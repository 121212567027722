import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import logoProjeto from 'root-images/logo_projeto.png';
import { Button } from '@bubotech/sumora-react-components';

interface ErrorBoundaryProps {
  navigate: NavigateFunction;
}

/**
 * Componente para lidar com erros inesperados que podem ocorrer
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { error: false, errorInfo: null, url: window.location.pathname };

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      error,
      errorInfo,
      url: window.location.pathname
    });
  }

  componentDidUpdate(): void {
    if (window.location.pathname !== this.state.url) 
      this.setState({
        error: false,
        errorInfo: null,
        url: window.location.pathname,
      });
  }

  handleGoBack() {
    this.props?.navigate(-1);
  }

  render(): React.ReactNode {
    return this.state.error ? 
      <div className='error-container'>
        <img alt='logo' className='logo-img' src={logoProjeto} />
        <h2>Algo deu errado...</h2>
        <Button onClick={this.handleGoBack}>Voltar</Button>
      </div> 
      : this.props.children;
  }
}

export default ErrorBoundary;